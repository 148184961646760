import {MD5, enc} from "crypto-js";
import {FILE_PATH} from "./contants";
import moment from "moment";
import {routeStore} from "../stores/stores";

export async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function pwdConvert(pwd) {
  return MD5(pwd).toString(enc.Base64);
}

export function isFileHash(hash) {
  return /^[0-9a-f]{32}#\w{3,4}:\d+$/.test(hash)
}

export function fileHashToPath(hash) {
  if (/^[0-9a-f]{32}#\w{3,4}:\d+$/.test(hash)) {
    const value = hash.substring(0, hash.indexOf(':'));
    const values = value.split("#");
    const result = [
      FILE_PATH,
      values[0].substring(0, 1),
      values[0].substring(1, 3),
      values[0].substring(3)
    ].join('/');
    return values.length > 1 ? `${result}.${values[1]}` : result;
  }
  return hash;
}

export function getRecentMonth(currentDate,n) {
  const month = moment(currentDate).subtract(n, 'months').format('YYYY-MM-DD');
  return month;
}

export function getRecentYear(currentDate, n) {
  if (n === 0) {
    const today = new Date(currentDate);
    const year = today.getFullYear();
    return `${year}-01-01`;
  }
  const date = moment(currentDate).subtract(n, 'years').format('YYYY-MM-DD');
  return date;
}

export function getDates(date, dates) {
  let dateIndex = 0;
  for (let i = 0; i < dates.length; i++) {
    const item = dates[i];
    if (!compDateTime(date, item)) {
      dateIndex = i;
      break;
    }
  }
  return {dates: dates.slice(dateIndex), index: dateIndex};
}

export function compDateTime(d1, d2) {
  const date1 = new Date(Date.parse(d1));
  const date2 = new Date(Date.parse(d2));
  return date1 > date2;
}

export function  handleData(res) {
  const dates = [];
  const data = [];
  for (let i = 0; i < res.length; i++) {
    if (res[i]) {
      const {name, datas} = res[i];
      const value = [];
      datas.forEach((item) => {
        if (i ===  0) {
          dates.push(moment(item.date).format('YYYY/MM/DD'));
        }
        value.push(item.value);
      })
      data.push({name, value})
    }
  }
  return {date: dates, data}
}

// 判断模块显不显示
export function isShowModule(module, key) {
  const menus = routeStore.projects[routeStore.currentProjectIndex].remark;
  if (menus) {
    const dic = menus[module];
    if (dic) {
      if (!key) {
        return true;
      } else if (dic.includes(key)) {
        return true;
      } else {
        return false;
      }
    }else {
      return false;
    }
  }else {
    return true;
  }
}

export function groupBy(array, f) {
  let groups = {};
  array.forEach(function (o) {
      var group = JSON.stringify(f(o));
     
      groups[group] = groups[group] || [];
      groups[group].push(o);
  });
  return Object.keys(groups).map(function (group) {
      return groups[group];
  });
};

export function arrayGroupBy(list, groupId) {
  let sorted = groupBy(list, function (item) {
      return [item[groupId]];
  });
  return sorted;
};

export function getLastFriday (date = new Date()) {
   // 获取当前日期
   const momentDate = moment(date);
   let end = moment(date).subtract(1, 'days');
  // 判断给定日期是否是周日或星期一
   if (momentDate.isoWeekday() === 7 || momentDate.isoWeekday() === 1) {
     // 如果是周日或星期一，获取最近的已经发生的周五的日期
     const lastFriday = momentDate.clone().day(-2);
     if (lastFriday.isAfter(momentDate)) {
       // 如果最近的周五在给定日期之后，则取上一周的周五
       lastFriday.subtract(7, 'days');
     }
     end = lastFriday;
   } 
   return end;
}
