import React, {useEffect, useState} from "react";
import Loadable from '@chaosoft/react-loadable';
import {PageLoading} from "@ant-design/pro-layout";
import * as stores from "./stores/stores";
import {Provider, observer} from "mobx-react";
import {BrowserRouter, Redirect, Route as ReactRoute, Switch} from "react-router-dom";
// import routes, { getRoutes} from "./routes";
import {ConfigProvider} from "antd";
import zhCN from 'antd/lib/locale/zh_CN';
import { useStores } from "./stores/hooks";

const App = () => {

  return (
    <Provider {...stores}>
      <ConfigProvider locale={zhCN}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
        <AppRouter />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  )
}

const AppRouter = observer(() => {
  useEffect(async() => {
    await stores.routeStore.loadProjects();
    await stores.routeStore.getRoutes();
  },  []);

  const importRouteComponent = (routes) => {
    return routes.map(item => ({
      ...item,
      component: item.component && (typeof item.component === 'string' ? Loadable({
        loader: () => import(`${item.component}`),
        loading: () => <PageLoading/>,
      }) : item.component),

      routes: item.routes && importRouteComponent(item.routes)
    }))
  }

  const createLazyRoute = (routes) => {
    return routes && (
      <Switch>
        {routes.map(({path, Route = ReactRoute, component: Component, redirect, routes: childs, ...restProps}, index) => {
          if (redirect) {
            return <Redirect key={index} {...restProps} from={path} to={redirect}/>
          }
          return (
            <Route key={index} path={path} {...restProps} routes={childs} render={(props) => {
              if (Component) {
                return (
                  <Component {...props} routes={childs}>
                    {childs && childs.length > 0 && createLazyRoute(childs)}
                  </Component>
                )
              }
              return childs && childs.length > 0 && createLazyRoute(childs);
            }}/>
          )
        })}
      </Switch>
    )
  }

  return (
    <div>
      {stores.routeStore.routes.length > 0 && createLazyRoute(importRouteComponent(stores.routeStore.routes))}
    </div>
  )
})

export default () => {
  return (
    <App/>
  )
};
