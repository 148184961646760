export const trendJson = {
    "data": {
        "ft3": [
            {
                "name": "ft3",
                "mptRtn": "0",
                "mptDate": "2022-08-01 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "TEMDLRA LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTSIACU LX Equity",
                        "weight": "0.08"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.16"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "TEMASAA LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "100"
            },
            {
                "name": "ft3",
                "mptRtn": "0.00",
                "mptDate": "2022-08-02 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "TEMDLRA LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTSIACU LX Equity",
                        "weight": "0.08"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.16"
                    },
                    {
                        "bloombergTicker": "TEMASAA LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "99.84"
            },
            {
                "name": "ft3",
                "mptRtn": "0.00",
                "mptDate": "2022-08-03 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "TEMDLRA LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTSIACU LX Equity",
                        "weight": "0.08"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.16"
                    },
                    {
                        "bloombergTicker": "TEMASAA LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "100.00"
            },
            {
                "name": "ft3",
                "mptRtn": "0.00",
                "mptDate": "2022-08-04 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "TEMDLRA LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTSIACU LX Equity",
                        "weight": "0.08"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.16"
                    },
                    {
                        "bloombergTicker": "TEMASAA LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "100.00"
            },
            {
                "name": "ft3",
                "mptRtn": "0.00",
                "mptDate": "2022-08-05 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "TEMDLRA LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTSIACU LX Equity",
                        "weight": "0.08"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.16"
                    },
                    {
                        "bloombergTicker": "TEMASAA LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "99.84"
            },
            {
                "name": "ft3",
                "mptRtn": "0.00",
                "mptDate": "2022-08-08 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "TEMDLRA LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTSIACU LX Equity",
                        "weight": "0.08"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.16"
                    },
                    {
                        "bloombergTicker": "TEMASAA LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "100.00"
            },
            {
                "name": "ft3",
                "mptRtn": "0.00",
                "mptDate": "2022-08-09 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "TEMDLRA LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTSIACU LX Equity",
                        "weight": "0.08"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.16"
                    },
                    {
                        "bloombergTicker": "TEMASAA LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "100.00"
            },
            {
                "name": "ft3",
                "mptRtn": "0.00",
                "mptDate": "2022-08-10 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "TEMDLRA LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTSIACU LX Equity",
                        "weight": "0.08"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.16"
                    },
                    {
                        "bloombergTicker": "TEMASAA LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "100.06"
            },
            {
                "name": "ft3",
                "mptRtn": "0.00",
                "mptDate": "2022-08-11 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "TEMDLRA LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTSIACU LX Equity",
                        "weight": "0.08"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.16"
                    },
                    {
                        "bloombergTicker": "TEMASAA LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "100.00"
            },
            {
                "name": "ft3",
                "mptRtn": "0.00",
                "mptDate": "2022-08-12 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "TEMDLRA LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTSIACU LX Equity",
                        "weight": "0.08"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.16"
                    },
                    {
                        "bloombergTicker": "TEMASAA LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "100.00"
            },
            {
                "name": "ft3",
                "mptRtn": "0.00",
                "mptDate": "2022-08-15 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "TEMDLRA LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTSIACU LX Equity",
                        "weight": "0.08"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.16"
                    },
                    {
                        "bloombergTicker": "TEMASAA LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "99.94"
            },
            {
                "name": "ft3",
                "mptRtn": "0.00",
                "mptDate": "2022-08-16 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "TEMDLRA LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTSIACU LX Equity",
                        "weight": "0.08"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.16"
                    },
                    {
                        "bloombergTicker": "TEMASAA LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "100.00"
            },
            {
                "name": "ft3",
                "mptRtn": "0.00",
                "mptDate": "2022-08-17 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "TEMDLRA LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTSIACU LX Equity",
                        "weight": "0.08"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.16"
                    },
                    {
                        "bloombergTicker": "TEMASAA LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "100.00"
            },
            {
                "name": "ft3",
                "mptRtn": "0.00",
                "mptDate": "2022-08-18 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "TEMDLRA LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTSIACU LX Equity",
                        "weight": "0.08"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.16"
                    },
                    {
                        "bloombergTicker": "TEMASAA LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "99.94"
            },
            {
                "name": "ft3",
                "mptRtn": "0.00",
                "mptDate": "2022-08-19 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "TEMDLRA LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTSIACU LX Equity",
                        "weight": "0.08"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.16"
                    },
                    {
                        "bloombergTicker": "TEMASAA LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "99.78"
            },
            {
                "name": "ft3",
                "mptRtn": "0.00",
                "mptDate": "2022-08-22 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "TEMDLRA LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTSIACU LX Equity",
                        "weight": "0.08"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.16"
                    },
                    {
                        "bloombergTicker": "TEMASAA LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "99.86"
            },
            {
                "name": "ft3",
                "mptRtn": "0.00",
                "mptDate": "2022-08-23 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "TEMDLRA LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTSIACU LX Equity",
                        "weight": "0.08"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.16"
                    },
                    {
                        "bloombergTicker": "TEMASAA LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "100.00"
            },
            {
                "name": "ft3",
                "mptRtn": "0.00",
                "mptDate": "2022-08-24 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "TEMDLRA LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTSIACU LX Equity",
                        "weight": "0.08"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.16"
                    },
                    {
                        "bloombergTicker": "TEMASAA LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "100.00"
            },
            {
                "name": "ft3",
                "mptRtn": "0.00",
                "mptDate": "2022-08-25 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "TEMDLRA LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTSIACU LX Equity",
                        "weight": "0.08"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.16"
                    },
                    {
                        "bloombergTicker": "TEMASAA LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "100.00"
            },
            {
                "name": "ft3",
                "mptRtn": "0.00",
                "mptDate": "2022-08-26 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "TEMDLRA LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTSIACU LX Equity",
                        "weight": "0.08"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.16"
                    },
                    {
                        "bloombergTicker": "TEMASAA LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "99.94"
            },
            {
                "name": "ft3",
                "mptRtn": "0.00",
                "mptDate": "2022-08-29 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "TEMDLRA LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTSIACU LX Equity",
                        "weight": "0.08"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.16"
                    },
                    {
                        "bloombergTicker": "TEMASAA LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "99.94"
            },
            {
                "name": "ft3",
                "mptRtn": "0.00",
                "mptDate": "2022-08-30 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "TEMDLRA LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.35"
                    },
                    {
                        "bloombergTicker": "FTSIACU LX Equity",
                        "weight": "0.08"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.16"
                    },
                    {
                        "bloombergTicker": "TEMASAA LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "100.00"
            },
            {
                "name": "ft3",
                "mptRtn": "-0.01",
                "mptDate": "2022-08-30 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "FKUTX US Equity",
                        "weight": "0.33"
                    },
                    {
                        "bloombergTicker": "TEMFHAC LX Equity",
                        "weight": "0.21"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.34"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.06"
                    },
                    {
                        "bloombergTicker": "FGCAAUS LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "99.13"
            },
            {
                "name": "ft3",
                "mptRtn": "0.00",
                "mptDate": "2022-08-31 00:00:00",
                "mptFunds": [
                    {
                        "bloombergTicker": "FKUTX US Equity",
                        "weight": "0.33"
                    },
                    {
                        "bloombergTicker": "TEMFHAC LX Equity",
                        "weight": "0.21"
                    },
                    {
                        "bloombergTicker": "FTGSAAU LX Equity",
                        "weight": "0.34"
                    },
                    {
                        "bloombergTicker": "TEMUSGI LX Equity",
                        "weight": "0.06"
                    },
                    {
                        "bloombergTicker": "FGCAAUS LX Equity",
                        "weight": "0.06"
                    }
                ],
                "mptNav": "99.67"
            }
        ]
    }
}