import {action, computed, observable} from "mobx";
import {loading} from "@chaosoft/mobx-loading";
import request from "../utils/request";
import moment from "moment";
import { handleData } from "../utils/common";
import { JRStockText } from "../component/JRStockText/JRStockText";
import { trendJson } from "../utils/trend";
import {routeStore} from './stores'

export class PerformanceStore {
  @observable overview =  {};
  @observable riskTypeRatio =  {};
  @observable performanceTrend =  {};

  @loading('performance/find')
  @action
  loadOverview = async (params) => {
    const res = await PerformanceStore.getOverview(params);
    let datas = [];
    let columns  = [{title: '', dataIndex: 'name', key: 'name', render: (text) => <h4 >{text}</h4>}];
    for (const key in res) {
      if (Object.hasOwnProperty.call(res, key)) {
        const group = res[key];
        group.forEach((item, index) => {
          const row = {name:  item.name, group: key, key: `${key}_${index}`};
          item.datas.forEach(({date, value}, i) => {
            if (key === Object.keys(res)[0] && index === 0) {
              columns.push({
                title: date,
                dataIndex: `y${i+1}`,
                key: `y${i+1}`,
                render: (text) => <JRStockText value={text} tailCharacter={date === '夏普指数' ? '' : '%'} placeholder="-" />
              })
            }
            row[`y${i+1}`] = value;
          })
          datas.push(row);
        })
      }
    }
    this.overview = {columns, datas};
    
  }

  @action
  loadTrend = async (params) => {
    const res = await PerformanceStore.getTrend(params);
    // const res = trendJson.data;
    let datas = {data: []};
    let dates = [];
    let holders = [];
    // 投组列表要放在前面
    const defaultArray = routeStore.groups;
    let sortKeys = [];
    let otherkeys = [];
    const keys = Object.keys(res).sort();
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (defaultArray.includes(key)) {
        sortKeys.push(key);
      }else {
        otherkeys.push(key);
      }
    }
    sortKeys = [...sortKeys, ...otherkeys];
    sortKeys.forEach((key => {
      if (Object.hasOwnProperty.call(res, key)) {
        const group = res[key];
        if (key === 'index' || key === 'benchmark') {
          if (group.length > 0) {
            group.forEach((item) => {
              const lineData = { value: [] };
              const {data} = handleData([item]);
              lineData.name = item.name;
              lineData.value = data[0].value;
              datas.data.push(lineData);
            })
          }
        } else {
          const lineData = { value: [] };
          group.forEach((item, i) => {
            const {mptDate, mptACccRtn, mptFunds, name} = item;
            if (key === sortKeys[0]) {
              dates.push(moment(mptDate).format('YYYY/MM/DD'));
              // holders.push(mptFunds);
            }
            lineData.name = name;
            lineData.value.push(mptACccRtn);
          })
          datas.data.push(lineData);
        }
      }
    }))
    
    datas = {
      ...datas,
      date: dates,
      holders,
    }
    this.performanceTrend = datas;
  }
  @action
  loadRiskType = async (params) => {
    // 项目下投组列表
    const defaultArray = routeStore.groups;
    const funcArray = [];
    // 对应投组列表的风险类型
    defaultArray.forEach((item) => {
      const func =  PerformanceStore.getRiskType({...params, mptName: decodeURIComponent(item)});
      funcArray.push(func);
    })
    const funcRes = await Promise.all(funcArray);
    const resArray = [];
    defaultArray.forEach((item,index) => {
      resArray.push({
        name:item, data:funcRes[index]
      })
    })
    //获取低风险资产
    let riskData = {};
    resArray.forEach(res => {
      const {date, data} = handleData(res.data);
      let RRData = {};
      for (let i = 0; i < data.length; i++) {
        if (data[i].name === 'RR1+RR2') {
          RRData = {
            name: res.name,
            value: data[i].value
          }
          break;
        }
      }
      if (!riskData.date) {
        riskData = {
          date: date,
          data: [RRData],
        }
      } else{
        riskData.data.push(RRData);
      }
    })
    this.riskTypeRatio = riskData;
  }

  @action
  resetData = () => {
    this.overview =  {};
    this.riskTypeRatio =  {};
    this.performanceTrend =  {};
  }
  
  // 业绩总览
  static getOverview = async (params) => {
    return request.get(`/performance/overview`, {params})
  }

  // 业绩走势
  static getTrend = async (params) => {
    return request.get(`/performance/trend`,  {params})
  }

  // 风险资产占比
  static getRiskType = async (params) => {
    return request.get(`/performance/riskType`, {params})
  }

}

export default new PerformanceStore();
