import {action, computed, observable} from "mobx";
import {loading} from "@chaosoft/mobx-loading";
import request from "../utils/request";
import { arrayGroupBy, handleData } from "../utils/common";
import { JRStockText } from "../component/JRStockText/JRStockText";
import styles from '../pages/performance-overview/performance-overview.scss';
import moment from "moment";

export class PerformanceOverviewStore {
  @observable overview =  {};

  @observable timeSlotPerformance = [];

  @loading('performance/find')
  @action
  loadOverview = async (params) => {
    const res = await PerformanceOverviewStore.getOverviews(params);
    const dateDescs = await PerformanceOverviewStore.getDatedesc(params);
    let datas = [];
    let columns  = [{title: '', dataIndex: 'name', key: 'name', render: (text) => <h4 >{text}</h4>}];
    for (const key in res) {
      if (Object.hasOwnProperty.call(res, key)) {
        const group = res[key];
        group.forEach((item, index) => {
          const row = {name:  item.name, group: key, key: `${key}_${index}`};
          item.datas.forEach(({date, value}, i) => {
            if (key === Object.keys(res)[0] && index === 0) {
              columns.push({
                title: (
                  <div>
                    <div className={styles.tableColumnTitle}>
                      <div className={styles.tableColumnTitleDate}>{dateDescs[i].value}</div>
                        {date}
                    </div>
                  </div>
                ),
                dataIndex: `y${i+1}`,
                key: `y${i+1}`,
                align:  'center',
                sorter: (a, b) => {
                  let valueA = a[`y${i+1}`]
                  let valueB = b[`y${i+1}`] // ignore upper and lowercase
                  if(valueA!= '-' &&  valueB != '-'){
                   return  valueA - valueB;
                  }

                  if(valueA == '-' || valueB == '-' ){
                    if (valueA == '-') {
                      return -1;
                    }
                    if (valueB == '-') {
                      return 1;
                    }
                  }
                  return 0;
                },
                render: (text) => <JRStockText value={text} tailCharacter={date === '夏普指数' ? '' : '%'} placeholder="-" />
              })
            }
            row[`y${i+1}`] = value;
          })
          datas.push(row);
        })
      }
    }
    this.overview = {columns, datas};
    
  }

  @action 
  loadSummary = async(endDate) => {
    const res = await PerformanceOverviewStore.getSummary(endDate);
    // 按照参数stage分组
    const arr = arrayGroupBy(res, 'stage');
    const datas = arr.map((data)  => {
      let performance = {};
      if (parseFloat(data[0].mptRtn) >= parseFloat(data[0].mptRtn)) {
        performance.highestGroup  = data[0];
        performance.lowestGroup  = data[1];
      }  else {
        performance.highestGroup  = data[1];
        performance.lowestGroup  = data[0];
      }
      return {
        title: `过去${data[0].stage}`,
        date: `${moment(data[0].startDate).format('YYYY/MM/DD')}-${moment(data[0].endDate).format('YYYY/MM/DD')}`,
        ...performance,
      }
    });
    this.timeSlotPerformance = datas;
  }


  @action
  resetData = () => {
    this.overview =  {};
  }
  
  // 业绩总览
  static getOverviews = async (endDate) => {
    return request.get(`/performance/overviews/${endDate}`)
  }

  // 绩效总览-周月之最
  static getSummary = async (endDate) => {
    return request.get(`/performance/summary/${endDate}`)
  }

  // 总览日期头
  static getDatedesc = async (endDate) => {
    return request.get(`/performance/desc/${endDate}`)
  }

}

export default new PerformanceOverviewStore();
