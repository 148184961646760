import React, {Component} from 'react';
import styles from './JRStockText.scss';
import Proptypes from 'prop-types';
/**
 * ---
 * page: JRStockText
 * ---
 *
 *
 * 用于显示收益率的文本,收益为正显示红色,收益为负显示绿色
 */

 export class JRStockText extends Component {

    displayColor = (value) => {
        if(value === undefined || value === '-') {
            return styles.defaultColor;
        }
        // let temp = Number(value);
        let temp = value.indexOf('-');
        if(temp === -1) {
            return styles.increaseColor;
        } else {
            return styles.decreaseColor;
        }
    };

    displayContent = (value) => {
        let {tailCharacter='', placeholder=''} = this.props;
        if(value === undefined || value === '-') {
            return placeholder;
        }
        // if(isNaN(value)) {
        //     return value;
        // }
        // let temp = Number(value);
        let temp = value.indexOf('-');
        if(temp === -1) {
            // return '+' + value + tailCharacter;
            return value + tailCharacter;
        } else {
            return value + tailCharacter;
        }
    };

    render(){
        let {value} = this.props;
        return(
            <div className={this.displayColor(value)}>{this.displayContent(value)}</div>
        );
    }
}

JRStockText.defaultProps = {
    increaseColor:'#FF0000',
    decreaseColor:'#008000 ',
};

JRStockText.propTypes = {
    /**
     * 当前数值,
     */
    value:Proptypes.string,
    /**
     * 大于等于0时颜色
     */
    increaseColor:Proptypes.string,
    /**
     * 小于0时颜色
     */
    decreaseColor:Proptypes.string,
    /**
     * 未赋值时的占位字符
     */
    placeholder:Proptypes.string,
    /**
     * 末尾自定义符号
     */
    tailCharacter:Proptypes.string,
};