import {action, computed, observable} from "mobx";
import {loading} from "@chaosoft/mobx-loading";
import request from "../utils/request";
import {handleData} from "../utils/common";
import moment from "moment";
import { JRStockText } from "../component/JRStockText/JRStockText";
import {routeStore} from './stores'

export class ReportStore {
  @observable dataDic = {
    ft3: [],
    ft6: [],
    ft9: []
  };
  @observable overview =  {};
  @observable equityDebtRatio =  {};
  @observable riskTypeRatio =  {};
  @observable performanceTrend =  {};
  @observable contribution =  {};
  @observable mptDetail =  {};
  @observable fundRatio =  {};
  

  @loading('report/find')
  @action
  loadOverview = async (params) => {
    const res = await ReportStore.getOverview(params);
    let datas = [];
    let columns  = [{title: '', dataIndex: 'name', key: 'name', render: (text) => <h4 >{text}</h4>}];
    for (const key in res) {
      if (Object.hasOwnProperty.call(res, key)) {
        const group = res[key];
        group.forEach((item, index) => {
          const row = {name:  item.name, group: key, key: `${key}_${index}`};
          item.datas.forEach(({date, value}, i) => {
            if (key === Object.keys(res)[0] && index === 0) {
              if (date !== '一日' && date !== '一周') {
                columns.push({
                  title: date,
                  dataIndex: `y${i+1}`,
                  key: `y${i+1}`,
                  render: (text) => <JRStockText value={text} tailCharacter={date === '夏普指数' ? '' : '%'} placeholder="-" />
                })
              }
            }
            row[`y${i+1}`] = value;
          })
          datas.push(row);
        })
      }
    }
    this.overview = {columns, datas};
    console.log('sssss', this.overview)
  }

  @action
  loadTrend = async (params) => {
    const res = await ReportStore.getTrend(params);
    // const res = trendJson.data;
    let datas = {data: []};
    let dates = [];
    let holders = [];
    // 投组列表要放在前面
    const defaultArray = routeStore.groups;
    let sortKeys = [];
    let otherkeys = [];
    const keys = Object.keys(res).sort();
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (defaultArray.includes(key)) {
        sortKeys.push(key);
      }else {
        otherkeys.push(key);
      }
    }
    sortKeys = [...sortKeys, ...otherkeys];
    sortKeys.forEach((key => {
      if (Object.hasOwnProperty.call(res, key)) {
        const group = res[key];
        if (key === 'index' || key === 'benchmark') {
          if (group.length > 0) {
            group.forEach((item) => {
              const lineData = { value: [] };
              const {data} = handleData([item]);
              lineData.name = item.name;
              lineData.value = data[0].value;
              datas.data.push(lineData);
            })
          }
        } else {
          const lineData = { value: [] };
          group.forEach((item, i) => {
            const {mptDate, mptACccRtn, mptFunds, name} = item;
            if (key === sortKeys[0]) {
              dates.push(moment(mptDate).format('YYYY/MM/DD'));
              holders.push(mptFunds);
            }
            lineData.name = name;
            lineData.value.push(mptACccRtn);
          })
          datas.data.push(lineData);
        }
      }
    }))
    
    datas = {
      ...datas,
      date: dates,
      holders,
    }
    this.performanceTrend = datas;
  }

  @action
  loadRiskType = async (params) => {
    const res = await ReportStore.getRiskType(params);
    this.riskTypeRatio = handleData(res);
  }

  @action
  loadHoldings = async (params) => {
    const res = await ReportStore.getHoldings(params);
    this.equityDebtRatio = handleData(res);
  }

  @action
  loadFundRatio = async (params) => {
    const res = await ReportStore.getFundRatio(params);
    this.fundRatio = handleData(res);
  }

  @action
  loadMptDetails = async (params) => {
    const res = await ReportStore.getMptDetails(params);
    let datas = [];
    let columns  = [{title: '基金代码', dataIndex: 'ftTicker', key: 'ftTicker'},{title: '名称', dataIndex: 'nameCh', key: 'nameCh'},{title: '占比', dataIndex: 'weight', key: 'weight'},{title: '持有期（自然日）', dataIndex: 'holdingPeriod', key: 'holdingPeriod'},{title: '业绩', dataIndex: 'performance', key: 'performance'},{title: '风险等级', dataIndex: 'risk', key: 'risk'},{title: '买入日期', dataIndex: 'buyTime', key: 'buyTime'},{title: '卖出日期', dataIndex: 'sellTime', key: 'sellTime'}];
    res.forEach(({ftTicker, nameCh,risk, performance, weight,holdingPeriod, buyTime, sellTime }, index) => {
      const row = {ftTicker, nameCh,risk, performance, weight,holdingPeriod, buyTime: moment(buyTime).format('YYYY-MM-DD'),sellTime:sellTime ? moment(sellTime).format('YYYY-MM-DD') : '-', key: `${index}`};
      datas.push(row);
    })

    this.mptDetail = {columns, datas};
  }

  @action
  loadContribution = async (params) => {
    const res = await ReportStore.getContribution(params);
    this.contribution = res;
  }

  @action
  resetData = () => {
    this.overview =  {};
    this.equityDebtRatio =  {};
    this.riskTypeRatio =  {};
    this.performanceTrend =  {};
    this.contribution =  {};
    this.mptDetail =  {};
    this.fundRatio =  {};
  }

  // 业绩总览
  static getOverview = async (params) => {
    return request.get(`/performance/overview`, {params})
  }

  // 业绩走势
  static getTrend = async (params) => {
    return request.get(`/performance/trend`,  {params})
  }

  // 风险资产占比
  static getRiskType = async (params) => {
    return request.get(`/performance/riskType`, {params})
  }
  
  // 持仓分析,股债比
  static getHoldings = async (params) => {
    return request.get(`/performance/holdings`, {params})
  }

  // 基金占比走势
  static getFundRatio = async (params) => {
    return request.get(`/performance/fundRatio`, {params})
  }

  // 投组详情
  static getMptDetails = async (params) => {
    return request.get(`/performance/mptDetails`, {params})
  }

  // 月贡献率
  static getContribution = async (params) => {
    return request.get(`/performance/contribution`, {params})
  }
}

export default new ReportStore();
