import request from "../utils/request";
import {action, computed, observable} from "mobx";
import {LineChartOutlined, HomeOutlined, TableOutlined} from "@ant-design/icons";
import moment from "moment";
import {START_DATE} from "../utils/contants";

export class RouteStore {
  @observable
  routes = [];
  @observable
  projects = [];
  @observable
  currentProjectIndex = 0;
  @observable
  groups = [];
  @observable
  startDate = START_DATE;

  @action
  loadProjects = async () => {
    const res = await RouteStore.getProjects();
    this.projects = res;
  }

  @action
  getRoutes = async (index) => {
    const routes = {
      path: '/',
      component: './layout/basic-layout',
      routes: [{
        path: '/',
        redirect: '/home',
        exact: true
      }, {
        path: '/home',
        name: '首页',
        icon: <HomeOutlined />,
        component: './pages/home',
        exact: true
      }, {
        path: '/performance',
        name: '绩效总览',
        icon: <TableOutlined />,
        component: './pages/performance-overview/performance-overview',
        exact: true
      }]
    };
    if (this.projects.length === 0) {
      return routes;
    }

    this.currentProjectIndex = index ? index : 0;
    const res = await RouteStore.getProjectGroups(this.projects[this.currentProjectIndex].key);
    const {startDate, mptNames} = res;
    this.groups = mptNames;
    this.startDate = startDate ? moment(startDate).format('YYYY-MM-DD') : START_DATE;
    routes.routes.push({
      path: '/single',
      name: '单只绩效',
      icon: <LineChartOutlined />,
      component: './pages/single-performance/single-performance',
      exact: true
    });
    const report =  {
      path: '/report',
      name: '月报',
      icon: <LineChartOutlined />,
      routes: []
    };
    this.groups.forEach(project => {
      const temp = {
        path: `/report/${project}`,
        name: project,
        component: './pages/report/riskLevel/riskLevel',
        exact: true,
      }
      report.routes.push(temp);
    });
    routes.routes.push(report);
    routes.routes.push({
      path: '/predict',
      name: 'AI预测',
      icon: <TableOutlined />,
      component: './pages/ai-predict/ai-predict',
      exact: true,
      hideInMenu: true,
    });
    this.routes = [routes];
    return this.routes;
  };

  static getProjectGroups = async (id) => {
    return request.get(`/manage/projects/${id}`)
  }

  // 项目列表
  static getProjects = async () => {
    return request.get(`/manage/projects`);
  } 
}

export default new RouteStore();
