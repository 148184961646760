import request from "../utils/request";
import {action, computed, observable} from "mobx";
import {Auth} from "@chaosoft/admin";
import {message} from "antd";
import {LocalStore} from "./local.store";
import {pwdConvert} from "../utils/common";

export class AdminStore extends LocalStore {

  @observable
  admin = {}

  @computed
  get loginInfo() {
    return this.getItem("login");
  }

  get storeName() {
    return "admin";
  }

  @computed
  get isLogin() {
    return !!this.loginInfo?.token;
  }

  @computed
  get isLoadSelf() {
    return !!this.admin.key;
  }


  constructor() {
    super();
    if (this.isLogin) {
      Auth.reloadAuth(true);
    }
  }

  @action
  login = async ({loginType, auto, ...rest}) => {
    if (loginType === 'PASSWORD') {
      const {password} = rest;
      rest.password = pwdConvert(password);
    }
    const loginInfo = await AdminStore.postLogin({
      certType: loginType,
      tokenType: auto ? 'LONG_TIME' : 'TEMPORARY',
      content: {
        userType: 'ADMIN',
        ...rest,
      }
    });
    this.setItem("login", loginInfo);
    Auth.reloadAuth(true);
  }

  @action
  changePassword = async (change, origin) => {
    await AdminStore.postChangePwd({
      change: change && pwdConvert(change),
      origin: origin && pwdConvert(origin)
    });
    await this.logout();
  }

  @action
  logout = async () => {
    try {
      await AdminStore.postLogout();
    } catch (e) {
    } finally {
      this.removeLocalLogin();
    }
  }

  @action
  removeLocalLogin = () => {
    this.removeItem("login");
    Auth.reloadAuth(false);
    this.admin = {};
    location.reload();
  }


  @action
  loadLoginAdmin = async () => {
    if (!this.isLogin) {
      message.error("当前尚未登录！！！")
    } else {
      this.admin = await AdminStore.getSelf();
    }
  }

  static postLogin = async (data) => {
    return request.post('/admin/login', {data})
  }

  static getSelf = async () => {
    return request.get('/admin/self')
  }

  static postChangePwd = async (data) => {
    return request.post('/admin/change-pwd', {data})
  }

  static postLogout = async () => {
    return request.post('/admin/logout')
  }
}

export default new AdminStore();
