import {action, computed, observable} from "mobx";
import {loading} from "@chaosoft/mobx-loading";
import request from "../utils/request";
import {stringifyUrl} from "query-string";

export class UserStore {

  @observable datas = {
    list: [],
    pagination: {},
    params: {}
  }

  @computed get tableDatas() {
    const {list, pagination} = this.datas;
    return {list, pagination}
  }

  @computed get hasMorePage() {
    return this.datas?.pagination?.totalPages > 1
  }

  @loading('user/find')
  @action
  load = async ({pageable, params} = {}, force) => {
    const {content, ...page} = await UserStore.findUser({
      ...(force ? {} : this.datas.params),
      ...(params || {}),
      ...{page: 0, size: 10},
      ...(pageable ? {...pageable, page: pageable.page - 1} : {})
    });
    this.datas = {
      list: content,
      pagination: {...page, current: page.current + 1},
      params: {...this.datas.params, ...(params || {})}
    }
  }

  @loading('user/find')
  @action
  refresh = async () => {
    const {current: page = 1, pageSize: size = 10} = this.datas.pagination;
    await this.load({
      pageable: {page, size}
    });
  }

  @loading('user/save')
  @action
  save = async (key, user) => {
    await UserStore.putUser(key, user);
    await this.refresh();
  }

  @loading('user/save')
  @action
  setDisable = async (key, type, {duration, remove} = {}) => {
    if (remove) {
      await UserStore.deleteDisabled(key, type);
    } else {
      await UserStore.putDisabled(key, {type, duration});
    }
    await this.refresh();
  }

  static findUser = async (query) => {
    return request.get(stringifyUrl({
      url: '/user',
      query
    }, {skipEmptyString: true, skipNull: true}))
  }

  static putUser = async (key, user) => {
    return request.put(`/user/${key}`, {data: user});
  }

  static putDisabled = async (key, disable) => {
    return request.put(`/user/${key}/disabled`, {data: disable})
  }

  static deleteDisabled = async (key, type) => {
    return request.delete(stringifyUrl({
      url: `/user/${key}/disabled`,
      query: {disableType: type}
    }, {skipEmptyString: true, skipNull: true}))
  }

}

export default new UserStore();
